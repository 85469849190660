@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400;1,600;1,700;1,800&display=swap");
:root {
    --priamary-color: #1677ff;
    --secondary-color: #454545;
    --primary-light: #f0fffc;
    --secondary-light: #ececec;
    --text-light: #c1c1c1;
}

* {
    font-family: "Rubik", sans-serif;
}

body {
    background-color: var(--secondary-light);
    overflow-x: hidden;
}

:where(.css-dev-only-do-not-override-14wwjjs).ant-btn-primary {
    color: #fff !important;
    background-color: #1677ff !important;
    box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1) !important;
}

.rac-shadow {
    border: 1px solid #e3e3e3;
    transition: all 0.3s ease 0s;
}

.bg-light {
    background-color: var(--secondary-light);
}

.border-light {
    border-color: #d3d2d2;
}

.small-heading-title {
    font-size: 13px;
    font-weight: 600;
    color: #5b5b5b;
}

.avatar {
    position: unset;
}

/* AntDesign Overides */

.ant-upload-list-item-thumbnail .anticon svg {
    margin-top: 20px !important;
}

.ant-switch {
    background-color: #b5b0b0;
}

.ant-btn-primary {
    background-color: var(--priamary-color);
}

.ql-editor {
    height: 400px !important;
}
.ql-editor.legal {
    height: 100% !important;
}

.ql-container {
    margin: 0 !important;
}

@media screen and (min-width: 768px) {
    .center-full {
        position: absolute;
        left: 50%;
        top: 50%;
        -moz-transform: translate(-50%, -50%); /* Firefox */
        -ms-transform: translate(-50%, -50%); /* IE 9 */
        -webkit-transform: translate(-50%, -50%); /* Safari and Chrome*/
        -o-transform: translate(-50%, -50%); /* Opera */
        transform: translate(-50%, -50%);
    }
}
@media screen and (max-width: 500px) {
    :where(.css-dev-only-do-not-override-pr0fja).ant-picker-dropdown
        .ant-picker-panel-container
        .ant-picker-panels {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
    }
    :where(.css-dev-only-do-not-override-pr0fja).ant-menu
        .ant-menu-item-divider {
        overflow: hidden;
        line-height: 0;
        border-color: rgba(5, 5, 5, 0.06);
        border-style: solid;
        border-width: 0;
        border-top-width: 0px;
        margin-block: 1px;
        padding: 0;
    }
}

:where(
        .css-dev-only-do-not-override-14wwjjs
    ).ant-menu-light.ant-menu-root.ant-menu-inline,
:where(.css-dev-only-do-not-override-14wwjjs).ant-menu-light
    > .ant-menu.ant-menu-root.ant-menu-inline,
:where(
        .css-dev-only-do-not-override-14wwjjs
    ).ant-menu-light.ant-menu-root.ant-menu-vertical,
:where(.css-dev-only-do-not-override-14wwjjs).ant-menu-light
    > .ant-menu.ant-menu-root.ant-menu-vertical {
    border-inline-end: 1px solid rgb(255, 255, 255);
}
