p {
    font-size: 13px;
    color: #222;
}

p,
h1,
hr,
h3 span {
    color: #2f2f2f !important;
}

hr {
    border-color: #bcbcbc !important;
}

.main-document-agreement,
.vehicle-condition,
.terms-conditions {
    width: 1400px;
    border: 1px solid #cacaca;
    margin: auto;
    margin-top: 30px;
    border-radius: 10px;
    background-color: #fff;
}

.terms-conditions .ql-editor {
    height: 100% !important;
    padding: 5px !important;
}

.agreement-page .ql-container {
    margin: 30px auto 30px auto !important;
}

@media screen and (max-width: 550px) {
    .main-document-agreement,
    .vehicle-condition,
    .terms-conditions {
        max-width: 100vw;
    }
    .ant-qrcode {
        display: none;
    }
    .btn-container {
        flex-wrap: wrap;
    }
    .cv-container {
        flex-wrap: wrap;
    }
    .cv-container div:nth-child(1) div:nth-child(3),
    .cv-container div:nth-child(1) {
        width: 100%;
    }
    .cv-container div:nth-child(2) div:nth-child(3),
    .cv-container div:nth-child(2) {
        width: 100%;
    }
    .checkup-container {
        flex-wrap: wrap;
    }
    .checkup-container div {
        width: 100%;
        margin-bottom: 10px;
    }
    .rental-info {
        flex-wrap: wrap;
    }
    .rental-info p {
        width: 100%;
        margin-bottom: 10px;
    }
    .mask {
        width: 80px;
    }
}

@media print {
    .customer-agreement .float-button {
        display: none;
    }
    .actions-container {
        display: none;
    }
    .terms-conditions .ql-editor {
        height: 100% !important;
    }
    .ant-qrcode {
        display: block;
    }
    .main-document-agreement,
    .vehicle-condition,
    .terms-conditions {
        width: 1400px;
        border: unset !important;
        margin: auto;
        margin-top: 30px;
        background-color: #fff;
        page-break-after: always;
    }
    @page {
        margin: 0;
    }
    .btn-container {
        flex-wrap: unset;
    }
    .cv-container {
        flex-wrap: unset;
    }
    .cv-container div:nth-child(1) div:nth-child(3) {
        width: 310px;
    }
    .cv-container div:nth-child(2) div:nth-child(3) {
        width: 310px;
    }

    .checkup-container {
        flex-wrap: unset;
    }
    .checkup-container div {
        width: unset;
        margin-bottom: 0;
    }
    .rental-info {
        flex-wrap: wrap;
    }
    .rental-info p {
        width: unset;
        margin-bottom: 0;
    }
    .mask {
        width: 7rem;
    }
    .drawer-button {
        display: none;
    }
    .vehicle-condition {
        page-break-after: always;
    }
}
